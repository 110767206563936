import { Instance, types } from "mobx-state-tree"
import newId from "../utils/new-id"
import { SyncModel } from "../models/sync"

export const MAX_IMAGE_UPLOAD_DIMENSION = 1920
export const USER_PROFILE_UPLOAD_DIMENSION = 300
export const VIDEO_THUMBNAIL_UPLOAD_DIMENSION = 400

// these dimensions need to match pitch45-pulumi/lambdas/image-resize-viewer-request/viewer-request-handler.ts
export enum AssetDimensions {
  SmallSquare = "160x160",
  Portrait = "180x320",
  Landscape = "320x180",
  Cover = "641x191",
  Square = "400x400",
  FullSize = "FullSize",
}

export const AssetModel = types
  .model("Asset")
  .props({
    id: types.optional(types.string, () => newId().id),
    sync: types.optional(SyncModel, () => ({})),
  })
  // we're using volatile properties to store references to device data that we
  // shouldn't expect to persist across sessions
  .volatile(() => ({
    file: undefined as unknown as File | undefined,
    localPath: undefined as unknown as string | undefined,
  }))
  .actions((self) => ({
    clearLocalPath() {
      self.localPath = undefined
    },
    setFile(file: File) {
      self.file = file
    },
    setLocalPath(localPath: string) {
      self.localPath = localPath
    },
  }))

export type Asset = Instance<typeof AssetModel>

export interface AssetAddParams {
  localPath?: string
  file?: File
}

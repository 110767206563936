import {
  clone,
  flow,
  getParent,
  Instance,
  IStateTreeNode,
  toGenerator,
  types,
} from "mobx-state-tree"
import { NIL as EMPTY_UUID } from "uuid"
import { withEnvironment } from "./extensions/with-environment"
import { AssetApi } from "../services/api/asset-api"
import { SyncStatus } from "../models/sync"
import { Asset, AssetAddParams, AssetDimensions, AssetModel } from "../models/asset"
import logger from "../logging/logger"
import { RootStoreModel } from "./root-store"

export const AssetStoreModel = types
  .model("AssetStore")
  .props({
    uploadedAssetCache: types.map(AssetModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    createAsset(params: AssetAddParams) {
      const asset = AssetModel.create(params)
      if (params.localPath) {
        asset.setLocalPath(params.localPath)
      }
      if (params.file) {
        asset.setFile(params.file)
      }
      return asset
    },
    uploadMobileAsset: flow(function* (asset: Asset) {
      const token = yield* toGenerator(self.environment.secureStorage.getItemAsync("access-token"))

      if (!asset.localPath) {
        throw new Error("asset.localPath is undefined")
      }

      if (!token) {
        throw new Error("access-token is undefined")
      }

      const assetApi = new AssetApi(self.environment.api)
      yield* toGenerator(
        asset.sync.run(() => assetApi.uploadAsset(asset.id, asset.localPath!, token)),
      )

      const cachedAsset = clone(asset)
      // copy the volatile property to the cached asset to that we can use it in
      // AssetImage even when passing just the assetId
      cachedAsset.setLocalPath(asset.localPath)
      self.uploadedAssetCache.set(asset.id, cachedAsset)
      return asset
    }),
    uploadWebAsset: flow(function* (asset: Asset) {
      const assetApi = new AssetApi(self.environment.api)

      if (!asset.file) {
        throw new Error("asset.file is undefined")
      }

      yield* toGenerator(asset.sync.run(() => assetApi.uploadWebAsset(asset.id, asset.file!)))

      const cachedAsset = clone(asset)
      if (asset.localPath) {
        cachedAsset.setLocalPath(asset.localPath)
      }
      self.uploadedAssetCache.set(asset.id, cachedAsset)
      return asset
    }),
  }))
  .views((self) => ({
    getPublicUri(assetId: string | undefined, dimensions: AssetDimensions) {
      if (!assetId) {
        return undefined
      } else if (assetId === EMPTY_UUID) {
        logger.logWarning("AssetStore.getPublicUri: assetId is empty")
        return undefined
      }
      let uri = `${self.environment.publicAssetBaseUrl}/${assetId}`
      if (dimensions !== AssetDimensions.FullSize) {
        uri += `?d=${dimensions}`
      }
      return uri
    },
    toAsset(id: string): Asset {
      const cachedAsset = self.uploadedAssetCache.get(id)
      if (!cachedAsset) {
        return AssetModel.create({ id, sync: { status: SyncStatus.Synced } })
      }
      return cachedAsset
    },
  }))
  .views((self) => ({
    toAssets(assetIds) {
      return assetIds.map((id) => self.toAsset(id))
    },
  }))

export type AssetStore = Instance<typeof AssetStoreModel>
export const withAssetStore = (self: IStateTreeNode) => ({
  views: {
    get assetStore(): AssetStore {
      return getParent<Instance<typeof RootStoreModel>>(self).assetStore
    },
  },
})
